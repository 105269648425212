import React from 'react';
import { orderShippingType, printType } from '../../constants/orderStatus';
import logoList from '../../constants/logoList';

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

const InvoiceHeader = ({ order, type }) => (
  <>
    <div className="invoice-header-container">
      <div className="invoice-header-logo">
        <img
          src={logoList?.[CLIENT]?.PRINT_LOGO}
          alt="logo"
          className="invoice-header-logo-size"
        />
      </div>
      <div className="invoice-header-name">
        {CLIENT === 'woodworth' ? (
          <>
            <h1>Woodworth Cabinetry</h1>
            <br />
            <p>Address: 854 Westport Cres</p>
            <p>Mississauga ON, L5T 1N5</p>
            <p>Tel: 905 670 8787</p>
            <p>https://woodworthcabinetry.com</p>
            <p>Email: sales@de-valor.ca</p>
          </>
        ) : (
          <>
            <h1>DW Cabinetry</h1>
            <br />
            <p>Address: 10676 King William Dr</p>
            <p>Dallas, TX 75220 USA</p>
            <p>Tel: 214 242 8975</p>
            <p>https://dw-cabinetry.com</p>
            <p>Email: sales@dw-cabinetry.com</p>
          </>
        )}
      </div>
      {type === printType.INVOICE && (
        <div className="invoice-header-invoice-no">
          <div>
            <h2 style={{ textAlign: 'center' }}>Invoice</h2>
          </div>
          <br />
          <div>
            <table
              className="invoice-header-top-table"
              style={{ width: '100%' }}>
              <tbody>
                <tr className="invoice-header-top-table">
                  <td className="invoice-header-top-table">Date</td>
                  <td className="invoice-header-top-table">Invoice#</td>
                </tr>
                <tr className="invoice-header-top-table">
                  <td className="invoice-header-top-table">
                    {order?.invoiceDate}
                  </td>
                  <td className="invoice-header-top-table">
                    {order?.invoiceNumber}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
    <div className="invoice-to-container">
      <div className="invoice-to-item">
        <div>{type === printType.INVOICE ? 'Invoice' : 'Quotation'} To</div>
        <div className="invoice-to-details">
          <p>{order?.company}</p>
          <p>{order?.clientName}</p>
          <p>
            {order?.address}, {order?.addressLine2}
          </p>
          <p>
            {order?.city}, {order?.province} {order?.postCode}
          </p>
          <p>T: {order?.phone}</p>
        </div>
      </div>
      {order?.shippingType === orderShippingType.SHIPPING && (
        <div className="invoice-to-item">
          <div>Ship To</div>
          <div className="invoice-to-details">
            <p>
              {order?.ordershippingaddress?.firstName}{' '}
              {order?.ordershippingaddress?.lastName}
            </p>
            <p>
              {order?.ordershippingaddress?.address},{' '}
              {order?.ordershippingaddress?.address1}
            </p>
            <p>
              {order?.ordershippingaddress?.city},{' '}
              {order?.ordershippingaddress?.province}{' '}
              {order?.ordershippingaddress?.postCode}
            </p>
            <p>T: {order?.ordershippingaddress?.phone}</p>
          </div>
        </div>
      )}
    </div>
  </>
);

export default InvoiceHeader;
