import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Modal } from '@mui/material';
import { getSpecBookStyles } from '../../store/actions/specBookActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const getDotColor = stock => {
  if (stock <= 5) return 'red';
  if (stock <= 20) return 'yellow';
  return 'green';
};

export const StockDot = ({ productColorStock }) => (
  <span
    style={{
      display: 'inline-block',
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      backgroundColor: getDotColor(productColorStock),
    }}
  />
);

const StyleProduct = ({ product }) => {
  const productColorStock = useMemo(() => {
    const eachItemQty = product?.productitems?.map(productItem => {
      const { quantity, item } = productItem;
      const { stock } = item;

      return Math.floor(stock / quantity) || 0;
    });

    if (eachItemQty?.length === 0) {
      return 0;
    }
    return Math.min(...eachItemQty);
  }, [product]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" style={{ width: '80px' }}>
        {product?.picLink && (
          <>
            <button
              type="button"
              onClick={handleOpen}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                cursor: 'pointer',
              }} // Removes button styling
              aria-label="Open product image">
              <img
                src={product?.picLink}
                alt="product color pic"
                className="display-item-pic"
                onError={({ target }) => {
                  const imgTarget = target;
                  imgTarget.src =
                    'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png';
                  imgTarget.onerror = null;
                }}
              />
            </button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '80%',
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                }}>
                <img
                  src={product?.picLink}
                  alt="product color big pic"
                  className="display-item-big-pic"
                  onError={({ target }) => {
                    const imgTarget = target;
                    imgTarget.src =
                      'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png';
                    imgTarget.onerror = null;
                  }}
                />
              </Box>
            </Modal>
          </>
        )}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        style={{ width: '50px', textAlign: 'center' }}>
        <StockDot productColorStock={productColorStock} />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {product.skuNo}
      </StyledTableCell>

      <StyledTableCell align="right">
        ${product?.MSRPinCAD?.toFixed(2)}
      </StyledTableCell>
    </StyledTableRow>
  );
};

const ProductLists = ({ styleId, selectedStyle }) => {
  const dispatch = useDispatch();
  const { styleProducts } = useSelector(state => state.specBook);
  useEffect(() => {
    if (styleId) {
      dispatch(getSpecBookStyles({ productStyleId: styleId }));
    }
  }, [styleId, dispatch]);

  return (
    <div>
      <h4 style={{ marginTop: '20px', marginBottom: '20px' }}>
        {selectedStyle.name}
      </h4>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        {selectedStyle.description}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Stock</StyledTableCell>
              <StyledTableCell>Item</StyledTableCell>
              <StyledTableCell align="right">Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {styleProducts?.map(product => (
              <StyleProduct product={product} key={product.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductLists;
