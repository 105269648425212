import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { login } from '../store/actions/authActions';
import { siteKey } from '../config/default';
import AppForm from '../components/form/AppForm';
import AppInput from '../components/form/AppInput';
import SubmitButton from '../components/form/SubmitButton';
import logoList, { titleList } from '../constants/logoList';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
  password: Yup.string()
    .required()
    .min(4, 'Must be at least 4 characters')
    .label('Password'),
});

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const { userInfo } = useSelector(state => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo, navigate]);

  return (
    <Container>
      <div className="login-container">
        <div>
          <img
            src={logoList?.[CLIENT]?.LOGO}
            alt="logo"
            className="login-logo"
          />
        </div>

        <div className="login-box">
          <h2 style={{ textAlign: 'center', padding: '20px' }}>
            {titleList?.[CLIENT]?.TITLE} SYSTEM LOGIN
          </h2>
          <AppForm
            initialValues={{ email: '', password: '' }}
            onSubmit={async values => {
              const googleRes = await recaptchaRef?.current?.executeAsync();
              recaptchaRef?.current?.reset();
              const updateValues = { ...values, googleRes };
              dispatch(login(updateValues));
            }}
            validationSchema={validationSchema}>
            <AppInput name="email" label="Email" type="text" required />
            <AppInput
              name="password"
              label="Password"
              type="password"
              required
            />
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
            <SubmitButton title="Login" />
          </AppForm>
          <div className="login-link">
            <Link to="/forgetpassword">Forgot Password</Link>
            <Link to="/signin">Become a Dealer</Link>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Login;
