import { Container } from '@mui/material';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import AppForm from '../components/form/AppForm';
import { siteKey } from '../config/default';
import AppInput from '../components/form/AppInput';
import { forgetPassword } from '../store/actions/authActions';
import SubmitButton from '../components/form/SubmitButton';
import logoList from '../constants/logoList';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
});

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

const ForgetPassword = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Container>
      <div className="login-container">
        <div>
          <img
            src={logoList?.[CLIENT]?.LOGO}
            alt="logo"
            className="login-logo"
          />
        </div>
        <div className="login-box">
          <h2 style={{ textAlign: 'center', padding: '20px' }}>
            Forgot Password
          </h2>
          <AppForm
            initialValues={{ email: '' }}
            onSubmit={async values => {
              const googleRes = await recaptchaRef?.current?.executeAsync();
              recaptchaRef?.current?.reset();
              const updateValues = { ...values, googleRes };
              dispatch(forgetPassword(updateValues, navigate));
            }}
            validationSchema={validationSchema}>
            <AppInput name="email" label="Email" type="text" required />

            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
            <SubmitButton title="Send Request" />
          </AppForm>
        </div>
      </div>
    </Container>
  );
};

export default ForgetPassword;
